import './App.css';
import { CloudWallet, CloudWalletHttpClient, LocalKey, PassKey, CloudWalletStore, xpubToEthereumAddress } from '@sonydice/cloudwallet';
import { useState } from 'react';
import { ethers } from 'ethers';


import Button from '@mui/material/Button';
import {TextField } from '@mui/material';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Box} from "@mui/material";


//const CW_URL = "http://localhost:9019/api/v1/core";
const CW_URL = "https://cloud-w-test.com/api/v1/core";


async function testWalletCreation() {
  CloudWalletHttpClient.endpointUrl = CW_URL;
  const client = new CloudWalletHttpClient("Y2xvdWR3YWxsZXR1c2VyOiRhcHIxJENmRnI2dWt5JFJUTXowUi9MTHRNQjJWYy5OZXhTeTAK");

  // create a wallet object
  let signingKey = LocalKey.generate();
  const wallet = await CloudWallet.createWallet(signingKey, client);

  // Print backup info for this wallet
  console.log("WALLET:", wallet.id, "\n blob:", wallet.blob);
  return wallet;
}

async function testWeb2WalletCreation(name) {
  console.log("testWeb2WalletCreation(" + name + ")");
  CloudWalletHttpClient.endpointUrl = CW_URL;
  const client = new CloudWalletHttpClient("Y2xvdWR3YWxsZXR1c2VyOiRhcHIxJENmRnI2dWt5JFJUTXowUi9MTHRNQjJWYy5OZXhTeTAK");

  // create a wallet object
  let signingKey = await PassKey.generate(name, name +  " @ Sony Web2 Cloudwallet");
  const wallet = await CloudWallet.createWallet(signingKey, client);

  // Print backup info for this wallet
  console.log("WALLET:", wallet.id, "\n blob:", wallet.blob);
  return wallet;
}

function CreateWalletButton({ onClick }) {
  return (
    <Button variant="contained" onClick={onClick}>
      Create a wallet
    </Button>
  );
}

function CreateWeb2WalletButton({ onClick }) {
  const [name, setName] = useState("");
  return (
    <Stack direction="column" spacing={2}>
    <Button variant="contained" onClick={event => onClick({name})}>
      Create a Web2 wallet
    </Button>
    <TextField id="outlined-controlled" label="username" value={name} onChange={event => {
      setName(event.target.value);
    }}></TextField>
    </Stack>
  );
}

function SignPayloadButton({ wallet, onClick }) {
  const [signature, setSignature] = useState("");
  async function onClick() {
    const payload = "lalal";
    const signature = await wallet.signPayload(Buffer.from(payload, 'utf-8'));
    console.log("signature = ", signature);
    setSignature(signature);
  }
  return (
    <span>
      <Box display="flex" alignItems="center">
      <Button variant="contained" onClick={onClick} disabled={wallet == null}>
        Sign payload
      </Button>
      <Box border={1} borderRadius="4px" display="inline-block" padding="8px" marginLeft={'8px'}>
      signature={signature.slice(0, 16)}..{signature.slice(signature.length-16)}
      </Box>
      </Box>
    </span>
  );
}

function PersistButton({wallet})  {
  async function persist()  {
    let walletStore = new CloudWalletStore(new CloudWalletHttpClient("Y2xvdWR3YWxsZXR1c2VyOiRhcHIxJENmRnI2dWt5JFJUTXowUi9MTHRNQjJWYy5OZXhTeTAK"));
    walletStore.add(wallet);
  }
  return (
    <span>
      <Button variant="contained" onClick={persist} disabled={wallet == null}>
        Persist
      </Button>
    </span>
  );
}

function GetAddressButton({wallet})  {
  const [address, setAddress] = useState("");
  async function onClick() {
    const address = await wallet.ethGetAddress(0);
    console.log("address = ", address);
    setAddress(address);
  }
  return (
      <Box display="flex" alignItems="center">
      <Button variant="contained" onClick={onClick} disabled={wallet == null}>
        Get Address
      </Button>
      <Box border={1} borderRadius="4px" display="inline-block" padding="8px" marginLeft={'8px'}>
      address={address}
      </Box>
      </Box>
  );

}

function GetBalanceButton({wallet})  {
  const [balance, setBalance] = useState(0n);
  async function onClick() {
    const address = await wallet.ethGetAddress(0);
    //let provider = ethers.getDefaultProvider("matic-amoy", {alchemy: "nmNHG-4x0KPQagkEOzknX0bNsIuQrnQ1", exclusive: ['alchemy']}); // 1946 is Soneium Minato testnet
    let provider = new CloudWalletHttpClient("Y2xvdWR3YWxsZXR1c2VyOiRhcHIxJENmRnI2dWt5JFJUTXowUi9MTHRNQjJWYy5OZXhTeTAK").getProvider("matic-amoy");
    let balance = await provider.getBalance(address);
    console.log("balance = " + balance);
    setBalance(balance);
  }
  return (
    <Box display="flex" alignItems="center">
      <Button variant="contained" onClick={onClick} disabled={wallet == null}>
        Get Balance
      </Button>
      <Box border={1} borderRadius="4px" display="inline-block" padding="8px" marginLeft={'8px'}>
      balance={ethers.formatEther(balance)}
      </Box>
    </Box>
  );

}

function GetMnemonicButton({wallet})  {
  const [mnemonic, setMnemonic] = useState("<no mnemonic>");
  async function onClick() {
    const result = await wallet.getMnemonic();
    setMnemonic(result.mnemonic);
  }
  return (
    <Box display="flex" alignItems="center">
      <Button variant="contained" onClick={onClick} disabled={wallet == null}>
        Get Mnemonic
      </Button>
      <Box border={1} borderRadius="4px" display="inline-block" padding="8px" marginLeft={'8px'}>
      {mnemonic}
      </Box>
    </Box>
  );

}

function TransferForm({wallet})  {
  const [target, setTarget] = useState("");
  const [amount, setAmount] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();
    const address = await wallet.ethGetAddress(0);
    let provider = ethers.getDefaultProvider("matic-amoy", {alchemy: "nmNHG-4x0KPQagkEOzknX0bNsIuQrnQ1", exclusive: ['alchemy']}); // 1946 is Soneium Minato testnet
    // Read the form data
    const form = e.target;
    const formData = new FormData(form);

    const formJson = Object.fromEntries(formData.entries());
    console.log(formJson);
    const tx = await wallet.getSigner(0, provider).sendTransaction({
      to: formJson.to,
      value: ethers.parseEther(formJson.amount),
    });
    console.log(tx);

  }


  return (
    <form method="post" onSubmit={handleSubmit}>
       <Box display="flex" alignItems="center">
        <TextField name="to" label="to" variant="outlined" />
        <TextField name="amount" label="amount" variant="outlined"/>
      <Button type="submit" variant="contained" disabled={wallet == null}>Transfer</Button>
      <Box padding="8px">
      <Button type="reset" variant="contained" >Reset</Button>
      </Box>
      </Box>

    </form>)

}


function WalletItem({wallet, setSelectedWallet, selectedWallet})  {
  async function onClick() {
    setSelectedWallet(wallet);
  }

  return (
    <TableRow key={wallet.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover onClick={onClick} selected={(wallet != null) && (wallet === selectedWallet)}>
      <TableCell >{wallet.id.slice(0, 4)}..{wallet.id.slice(-4)}</TableCell>
    </TableRow>
  )
}





function App() {
  CloudWalletHttpClient.endpointUrl = CW_URL;
  let walletStore = new CloudWalletStore(new CloudWalletHttpClient("Y2xvdWR3YWxsZXR1c2VyOiRhcHIxJENmRnI2dWt5JFJUTXowUi9MTHRNQjJWYy5OZXhTeTAK"));
  const [wallets, setWallets] = useState(walletStore.list());
  const [selectedWallet, setSelectedWallet] = useState(null);
  console.log("wallets = ", wallets);

  async function handleCreateWalletClick() {
    const wallet = await testWalletCreation();
    setWallets([...wallets, wallet]);
  }
  async function handleCreateWeb2WalletClick({name}) {
    const wallet = await testWeb2WalletCreation(name);
    setWallets([...wallets, wallet]);
  }
  async function handleClearPersistedWallets()  {
    let walletStore = new CloudWalletStore(new CloudWalletHttpClient("Y2xvdWR3YWxsZXR1c2VyOiRhcHIxJENmRnI2dWt5JFJUTXowUi9MTHRNQjJWYy5OZXhTeTAK"));
    walletStore.clear();
    setWallets([]);
  }

  const walletRows = wallets.map(wallet =>
      <WalletItem wallet={wallet} setSelectedWallet={setSelectedWallet} selectedWallet={selectedWallet}/>
  );
  return (
    <div className="App-header"> 
    <Stack spacing={4}>
        <Stack direction="row" spacing={2}>
          <CreateWalletButton onClick={handleCreateWalletClick} />
          <CreateWeb2WalletButton onClick={handleCreateWeb2WalletClick} />
          <Button variant="contained" onClick={handleClearPersistedWallets}>Clear Persisted Wallets</Button>
        </Stack>
        <Stack direction="row">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Wallet ID</TableCell>
              </TableRow>
            </TableHead>
            {walletRows}
          </Table>
        </TableContainer>
        </Stack>
        <Stack spacing={1}>
          {((selectedWallet != null) && ("Selected wallet:" + selectedWallet.id.slice(0, 4) + ".." + selectedWallet.id.slice(-4))) || "SELECT A WALLET FROM ABOVE LIST (IF ANY)"}
          <GetAddressButton wallet={selectedWallet} /> 
          <GetBalanceButton wallet={selectedWallet} /> 
          <GetMnemonicButton wallet={selectedWallet}/> 
          <PersistButton wallet={selectedWallet} /> 
          <SignPayloadButton wallet={selectedWallet} />
          <TransferForm wallet={selectedWallet}/>
        </Stack>
    </Stack>
    </div>
  );
}

export default App;
